import CssIcon from "../../static/icons/css3.svg"
import DockerIcon from "../../static/icons/docker.svg"
import ElasticSearchIcon from "../../static/icons/elasticsearch.svg"
import ExpoIcon from "../../static/icons/expo.svg"
import FirebaseIcon from "../../static/icons/firebase.svg"
import GatsbyIcon from "../../static/icons/gatsby.svg"
import GithubIcon from "../../static/icons/github.svg"
import GolangIcon from "../../static/icons/golang.svg"
import GoogleCloudIcon from "../../static/icons/google-cloud.svg"
import GraphQlIcon from "../../static/icons/graphql.svg"
import HasuraIcon from "../../static/icons/hasura.svg"
import HtmlIcon from "../../static/icons/html.svg"
import JavascriptIcon from "../../static/icons/javascript.svg"
import JestIcon from "../../static/icons/jest.svg"
import KubernetesIcon from "../../static/icons/kubernetes.svg"
import MaterialUiIcon from "../../static/icons/material-ui.svg"
import NestJSIcon from "../../static/icons/nestjs.svg"
import NextIcon from "../../static/icons/nextjs.svg"
import NodeJsIcon from "../../static/icons/nodejs.svg"
import PostGreSQLIcon from "../../static/icons/postgresql.svg"
import PythonIcon from "../../static/icons/python.svg"
import RabbitMqIcon from "../../static/icons/rabbitmq.svg"
import ReactIcon from "../../static/icons/react.svg"
import RedisIcon from "../../static/icons/redis.svg"
import ReduxSagaIcon from "../../static/icons/redux-saga.svg"
import ReduxIcon from "../../static/icons/redux.svg"
import TypescriptIcon from "../../static/icons/typescript.svg"
import SolidJSIcon from "../../static/icons/Solid.js.svg"

export enum Technologies {
  JAVASCRIPT,
  TYPESCRIPT,
  REACT_JS,
  REACT_NATIVE,
  NEXT_JS,
  GATSBY,
  HTML_5,
  CSS_3,
  FIREBASE,
  REDUX,
  REDUX_SAGA,
  GOOGLE_CLOUD,
  MATERIAL_UI,
  HASURA,
  JEST,
  NODE_JS,
  GITHUB,
  GRAPHQL,
  POSTGRESSQL,
  EXPO,
  DOCKER,
  GITHUB_ACTIONS,
  KUBERNETES,
  NESTJS,
  ELASTICSEARCH,
  RABBITMQ,
  REDIS,
  GOLANG,
  PYTHON,
  SOLID_JS,
}

interface TechnologyDesc {
  icon: string
  friendlyName: string
}
type TechnologyToIcon = {
  [technology in Technologies]: TechnologyDesc
}
const technologyToIcon: TechnologyToIcon = {
  [Technologies.JAVASCRIPT]: {
    icon: JavascriptIcon,
    friendlyName: "JavaScript",
  },
  [Technologies.TYPESCRIPT]: {
    icon: TypescriptIcon,
    friendlyName: "TypeScript",
  },
  [Technologies.REACT_JS]: { icon: ReactIcon, friendlyName: "React.js" },
  [Technologies.REACT_NATIVE]: {
    icon: ReactIcon,
    friendlyName: "React Native",
  },
  [Technologies.NEXT_JS]: { icon: NextIcon, friendlyName: "Next.js" },
  [Technologies.GATSBY]: { icon: GatsbyIcon, friendlyName: "Gatsby" },
  [Technologies.HTML_5]: { icon: HtmlIcon, friendlyName: "HTML5" },
  [Technologies.CSS_3]: { icon: CssIcon, friendlyName: "CSS3" },
  [Technologies.FIREBASE]: { icon: FirebaseIcon, friendlyName: "Firebase" },
  [Technologies.REDUX]: { icon: ReduxIcon, friendlyName: "Redux" },
  [Technologies.REDUX_SAGA]: {
    icon: ReduxSagaIcon,
    friendlyName: "Redux Saga",
  },
  [Technologies.GOOGLE_CLOUD]: {
    icon: GoogleCloudIcon,
    friendlyName: "Google Cloud",
  },
  [Technologies.MATERIAL_UI]: {
    icon: MaterialUiIcon,
    friendlyName: "Material-UI",
  },
  [Technologies.HASURA]: { icon: HasuraIcon, friendlyName: "Hasura" },
  [Technologies.JEST]: { icon: JestIcon, friendlyName: "Jest" },
  [Technologies.NODE_JS]: { icon: NodeJsIcon, friendlyName: "Node.js" },
  [Technologies.GITHUB]: { icon: GithubIcon, friendlyName: "Github" },
  [Technologies.GRAPHQL]: { icon: GraphQlIcon, friendlyName: "GraphQL" },
  [Technologies.POSTGRESSQL]: {
    icon: PostGreSQLIcon,
    friendlyName: "PostgreSQL",
  },
  [Technologies.EXPO]: {
    icon: ExpoIcon,
    friendlyName: "Expo",
  },
  [Technologies.DOCKER]: {
    icon: DockerIcon,
    friendlyName: "Docker",
  },
  [Technologies.KUBERNETES]: {
    icon: KubernetesIcon,
    friendlyName: "Kubernetes",
  },
  [Technologies.GITHUB_ACTIONS]: {
    icon: GithubIcon,
    friendlyName: "Github Actions",
  },
  [Technologies.NESTJS]: {
    icon: NestJSIcon,
    friendlyName: "NestJS",
  },
  [Technologies.ELASTICSEARCH]: {
    icon: ElasticSearchIcon,
    friendlyName: "Elasticsearch",
  },
  [Technologies.RABBITMQ]: {
    icon: RabbitMqIcon,
    friendlyName: "RabbitMQ",
  },
  [Technologies.REDIS]: {
    icon: RedisIcon,
    friendlyName: "Redis",
  },
  [Technologies.GOLANG]: {
    icon: GolangIcon,
    friendlyName: "Golang",
  },
  [Technologies.PYTHON]: {
    icon: PythonIcon,
    friendlyName: "Python",
  },
  [Technologies.SOLID_JS]: {
    icon: SolidJSIcon,
    friendlyName: "Solid.js",
  },
}

const getTechnologyDesc = (technology: Technologies) => {
  return technologyToIcon[technology]
}

const getTechnologyDescs = (technologies: Technologies[]) => {
  return technologies.map((technologyId) => getTechnologyDesc(technologyId))
}

export const technologies = () => {
  return getTechnologyDescs([
    Technologies.JAVASCRIPT,
    Technologies.TYPESCRIPT,
    Technologies.REACT_JS,
    Technologies.NODE_JS,
    Technologies.PYTHON,
    Technologies.GOOGLE_CLOUD,
    Technologies.GITHUB,
    Technologies.GRAPHQL,
    Technologies.POSTGRESSQL,
    Technologies.DOCKER,
    Technologies.SOLID_JS,
  ])
}
