import React from "react"
import { Jumbotron, Col, Row, Container } from "react-bootstrap"

//import custom styles
import SocialRow from "../SocialRow/SocialRow"
import MyJumbo from "../myJumbo/MyJumbo"
import MeImage from "../MeImage"

const Hero = () => (
  <MyJumbo
    title={"Hey, I'm a full stack developer from Belgium, living in Liège."}
    content={
      <>
        <Container fluid>
          <Row>
            <Col
              md="auto"
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MeImage />
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p>
                Have a project you'd like to discuss? Let's chat{" "}
                <a
                  style={{ color: "#00008B" }}
                  href="mailto:contact@arnaudcortisse.com"
                >
                  <span>contact@arnaudcortisse.com</span>
                </a>
              </p>
            </Col>
          </Row>
        </Container>

        <div className="mt-3">
          <SocialRow color="#000" />
        </div>
      </>
    }
  />
)

export default Hero
