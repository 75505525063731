import React from "react"
import Layout from "../components/layout/Layout"

import Hero from "../components/hero/Hero"
import Summary from "../components/summary/Summary"

//import my custom styles
import "../index.scss"
import { Pages } from "../pages"

export default () => (
  <Layout page={Pages.HOME}>
    <Hero />
    <hr />
    <Summary />
  </Layout>
)
