import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { GetMePictureQuery, GetMePicture } from "../graphql"

const MeImage: React.FC = () => {
  const data = useStaticQuery<GetMePictureQuery>(graphql`
    query GetMePicture {
      file(relativePath: { eq: "me.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(width: 184, height: 184, layout: FIXED)
        }
      }
    }
  `)

  return (
    <GatsbyImage
      decoding="sync"
      loading="eager"
      alt="Profile Picture"
      image={
        // @ts-ignore
        data?.file?.childImageSharp?.gatsbyImageData
      }
    />
  )
}

export default MeImage
