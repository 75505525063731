import React from "react"

import { Container, Row, Col } from "react-bootstrap"

import "../../fontawesome/css/all.min.css"

import { technologies, learningTechnologies } from "../../data/technologies"
import LastBlogPost from "../last-blog-post/last-blog-post"

interface MyLogoProps {
  src: string
  text: string
}
const MyLogo: React.FC<MyLogoProps> = ({ src, text }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <img src={src} style={{ width: 80, height: 80 }} alt={text} />
      <br />
      <span style={{ fontWeight: "bold", textAlign: "center" }}>{text}</span>
    </div>
  )
}

const Summary = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={12} lg={4} className="mb-3">
          <LastBlogPost />
        </Col>
        <Col xs={12} md={12} lg={8}>
          <h3>Stuff I've been using recently:</h3>
          <br />
          <Row>
            {[...technologies()].map((pieceOfStuff) => {
              return (
                <Col key={pieceOfStuff.friendlyName} xs="3" sm="2">
                  <MyLogo
                    src={pieceOfStuff.icon}
                    text={pieceOfStuff.friendlyName}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Summary
