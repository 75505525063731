import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GetLastBlogPostQuery, MarkdownRemarkFrontmatter } from "../../graphql"
import BlogCard from "../blog-card/blog-card.component"
import { Col, Container } from "react-bootstrap"

const LastBlogPost = () => {
  const data = useStaticQuery<GetLastBlogPostQuery>(graphql`
    query GetLastBlogPost {
      allMarkdownRemark(
        filter: {
          frontmatter: { published: { eq: true }, category: { eq: "blog" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
              subtitle
              cover {
                childImageSharp {
                  gatsbyImageData(width: 512, height: 512, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)

  const edge = data.allMarkdownRemark?.edges[0]

  const { cover, title, subtitle, date } = edge!.node!
    .frontmatter as MarkdownRemarkFrontmatter
  const to = `${edge.node?.fields?.slug}` || ""

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>Latest blog post</h3>
      <BlogCard title={title} cover={cover} date={date} slug={to} />
    </div>
  )
}

export default LastBlogPost
